import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react"
import ButtonLink from "./button-link"

interface IPageTitle {
  title: string;
  description: string;
  cta?: boolean;
  image?: string | any;
  state?: string;
}

const PageTitle = ({data, children}: {data: IPageTitle, children?: any}) => {    
  const state = data.state ? data.state : 'info'
  
  return (
    <section className={classNames({
      'container mx-auto md:grid grid-cols-12 gap-6 mb-16': true,
      'bg-brand-blue after:bg-brand-blue bg-fill-right': !data.cta && !data.image && state == 'info',
      'bg-brand-orange after:bg-brand-orange bg-fill-right': !data.cta && !data.image && state == 'error'
    })}>
      {/* Content */}
      <div className="col-start-1 row-start-1 col-span-9">
        <div className={classNames('md:grid grid-cols-9 gap-x-6 gap-y-4 py-16 px-6 md:px-0', {
          'bg-brand-blue bg-fill-left before:bg-brand-blue left-line': state == 'info',
          'bg-brand-orange bg-fill-left before:bg-brand-orange': state == 'error'
        })}>
          <h1 className="col-span-7 text-white">{data.title}</h1>
          <p className={classNames('col-span-5', {
            'text-slate-300': state == 'info',
            'text-white': state == 'error'
          })}>{data.description}</p>
        </div>
        {children && <div className="pt-16 px-6 md:px-0">
          {children}
        </div>}
      </div>
      

      {/* CTA */}
      {data.cta &&
        <div className="col-start-10 row-start-1 col-span-3 
                        hidden md:flex items-center justify-center
                        page-title__button-container">
          <ButtonLink to="/contact">Neem contact op</ButtonLink>
        </div>
      }

      {/* Image */}
      {data.image &&
        <div className="col-start-8 row-start-1 col-span-5 pt-16 px-6 md:px-0">
          <div className="img-deco-right">
            <img src={data.image} alt="" />
            {data?.image && <GatsbyImage
              image={getImage(data.image.localFile)}
              alt={data.image.alternativeText}
            />}
          </div>
        </div>
      }
      
    </section>
  )
}

export default PageTitle