import React from "react"
import classNames from "classnames"
import { FiArrowRight } from "react-icons/fi";


const Button = ({to, children, color = 'green', arrow = true}: any) => {
  return (
    <button className={classNames("flex gap-2 items-center justify-center uppercase transition-colors border-2 py-2 px-4 hover:text-white", {
      'text-brand-blue border-brand-blue hover:bg-brand-blue': color == 'blue',
      'text-brand-green border-brand-green hover:bg-brand-green': color == 'green',
      'text-brand-orange border-brand-orange hover:bg-brand-orange': color == 'orange',
      'text-white border-brand-green hover:bg-brand-green': color == 'green-white'
    })}>
      {children}
      {arrow ? <FiArrowRight/>  : ''}
    </button>  
  )
}

export default Button