import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ButtonLink from "../components/button-link"
import CaseCard from "../components/case-card"
import { graphql, useStaticQuery } from "gatsby"
import PageTitle from "../components/page-title"
import CasesGrid from "../components/cases-grid"
import ImageSection from "../components/image-section"
import Seo from "../components/seo"
import ContactLinks from "../components/contact-links"

const CasesPage = () => {
  // Fetch all cases at build time
  const { allStrapiCase } = useStaticQuery(graphql`
    query {
      allStrapiCase {
        nodes {
          ...CaseCard
        }
      }
    }
  `)

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Cases' }}/>
      
      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <PageTitle data={{
        title: 'Cases',
        description: 'Werk waar we trots op zijn en waar we andere bedrijven succes mee bezorgde. Overtuigd en wil je ook zo’n website? Laat het ons weten.',
        cta: true
      }}/>

      {/* Cases */}
      <CasesGrid cases={allStrapiCase.nodes} className="container mx-auto my-16 px-6 md:px-0" />

      {/* Section */}
      <ImageSection reverse image="https://cms.deltafuturum.nl/uploads/medium_profiel_a6848accff.jpg">
        <h1>Ook zo’n site laten bouwen?</h1>
        <p>
        Word jij nou ook enthousiast van de websites die wij bouwen? Wil je ook een geoptimaliseerde website die klanten oplevert? Laat het ons weten. Dan bespreken we hoe we jou kunnen helpen.
        </p>
        <ButtonLink to="/contact">Neem contact op</ButtonLink>
        <ContactLinks whatsapp={false} />
      </ImageSection>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default CasesPage