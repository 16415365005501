import React from "react";
import { FiMail, FiMessageCircle, FiPhone } from "react-icons/fi";

const ContactLinks = ({
  phone = true, 
  mail = true, 
  whatsapp = true
}) => {
  let links = [];

  if (phone) {
    links.push({
      link: 'tel:+31682089148',
      label: 'Bel ons',
      icon: <FiPhone />,
      value: '06 82089148'
    })
  }

  if (mail) {
    links.push({
      link: 'mailto:info@deltafuturum.nl',
      label: 'Mail ons',
      icon: <FiMail />,
      value: 'info@deltafuturum.nl'
    })
  }

  if (whatsapp) {
    links.push({
      link: 'https://wa.me/31682089148',
      label: 'App ons',
      icon: <FiMessageCircle />,
      value: '06 82089148'
    })
  }

  return (
    <div className="flex flex-col md:flex-row gap-x-12 gap-y-6">
      {links.map(({link, label, icon, value}, index) => (
        <a key={index} href={link} className="group">
          <h3 className="text-2xl font-bold text-brand-blue group-hover:text-brand-green transition-colors">{label}</h3>
          <p className="flex gap-2 items-center">{icon} {value}</p>
        </a>
      ))}
    </div>
  )
}

export default ContactLinks