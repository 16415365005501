import React from "react"
import '../styles/case-card.scss'
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "./button"

const CaseCard = ({project}: any) => {
  return (
    <Link to={`/cases/${project.slug}`} className="case relative pt-[75%] md:pt-[133%]">
      <div className="case__image-container absolute top-0">
        <GatsbyImage
          image={getImage(project.cover?.localFile)}
          alt={project.cover?.alternativeText}
          className="aspect-4/3 md:aspect-auto"
        />
      </div>
      <div className="case__details md:mt-0 mt-4 md:absolute bottom-0 flex flex-col items-start">
        <h3 className="font-bold text-2xl mb-2 text-brand-blue">{project.title}</h3>
        <Button to={`/cases/${project.slug}`} color="orange">Bekijk project</Button>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment CaseCard on STRAPI_CASE {
    id
    slug
    title
    cover {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 0.75)
        }
      }
    }
  }
`

export default CaseCard