import classNames from "classnames";
import React from "react"
import CaseCard from "./case-card";

const CasesGrid = ({cases, className}: any) => {  
  return (
    <div className={classNames('grid sm:grid-cols-2 lg:grid-cols-3 gap-6', className)}>
      {cases.map((project: any, index: number) => (
        <CaseCard key={index} project={project} />
      ))}
    </div>
  )
}

export default CasesGrid