import classNames from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

interface IImageSection {
  image: string; // GatsbyImage
  reverse: boolean;
  transparent: boolean;
  content: string; // Markdown
}

const ImageSection = ({data, image, children, reverse = false, transparent = false}: any) => {
  reverse = data?.reverse || reverse
  transparent = data?.transparent || transparent  

  return (
    <section className="container mx-auto md:grid grid-cols-12 items-start my-16">
        <div className={classNames('row-start-1 md:grid md:py-16 px-6 md:px-0 bg-brand-blue-light', {
          'col-start-4 col-span-9 grid-cols-9 bg-fill-right after:bg-brand-blue-light': !reverse,
          'col-start-1 col-span-8 grid-cols-8 bg-fill-left before:bg-brand-blue-light': reverse,
          'bg-transparent before:bg-transparent after:bg-transparent': transparent,
          'py-12': !transparent
        })}>

          {children && <div className={classNames('flex flex-col items-start gap-y-8', {
            'md:col-start-4 md:col-span-6 lg:col-start-4 lg:col-span-5': !reverse, // was col span 6
            'md:col-span-6 lg:col-start-2 lg:col-span-5': reverse
          })} >
            {children}
          </div>}

          {data && <div className={classNames('image-section-content flex flex-col items-start gap-y-8', {
            'md:col-start-4 md:col-span-6 lg:col-start-4 lg:col-span-5': !reverse, // was col span 6
            'md:col-span-6 lg:col-start-2 lg:col-span-5': reverse
          })} dangerouslySetInnerHTML={{
            __html: data.content.data.childMarkdownRemark.html,
          }}></div>}

        </div>

        {/* Image */}
        <div className={classNames('row-start-1 col-span-5 pt-12 md:py-16 px-6 md:px-0', {
          'col-start-1': !reverse,
          'col-start-8': reverse
        })}>
          <div className={classNames({
            'img-deco-diagonal': !reverse,
            'img-deco-right': reverse
          })}>
            <img src={image} alt="" />
            {data?.image && <GatsbyImage
            image={getImage(data.image.localFile)}
            alt={data.image.alternativeText}
          />}
          </div>
        </div>
      </section>
  )
}

export default ImageSection